import LoadingPage from "@components/Loader/LoadingPage";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

const FALLBACK_URL =
  process.env.NEXT_PUBLIC_FALLBACK_URL || "https://moongate.id";

const Index: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) return;
    router.push(FALLBACK_URL);
    return;
  }, [router, router.isReady]);
  return <LoadingPage />;
};

export default Index;
